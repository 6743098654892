.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

label {
  text-align: left;
  display: block;
}

.form-group {
  margin-bottom: 10px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

body {
  background-color: #fbfbfb;
}

.container {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 7px 9px -9px rgb(0 0 0 / 50%);
  background-color: #fff;
  padding: 15px;
}

.btn.btn-primary {
  background-color: #0095f6;
  border-color: #0095f6;
}

.btn.btn-primary.table-btn {
  float: right;
}

.pagination {
  align-items: center;
  justify-content: space-between;
}

.pagination .form-control {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

.pull-right {
  float: right;
}

.checkbox-position {
  vertical-align: middle;
}